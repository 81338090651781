<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('language.header')"
        prepend-icon="$languageHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card
      class="mb-4"
      flat>
      <v-card-text>
        <title-label
          :label="$t('language.language.title')"
          prepend-icon="$languageTitle" />
        <language-selection
          v-model="setting.uiLanguage"
          :text="$t('language.language.uiLanguage')"
          :items="ui"
          :state="state.uiLanguage"
          @input="submit('uiLanguage')">
        </language-selection>
        <language-selection
          v-model="setting.docLanguage"
          :text="$t('language.language.docLanguage')"
          :items="doc"
          :state="state.docLanguage"
          @input="submit('docLanguage')">
        </language-selection>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-text>
        <title-label
          :label="$t('language.currency.title')"
          prepend-icon="$coinTitle" />
        <language-selection
          v-model="setting.currency"
          :text="$t('language.currency.title')"
          :items="currency"
          :state="state.currency"
          @input="submit('currency')">
        </language-selection>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LanguageSelection from '@/views/ShopSetting/Pages/Language/components/LanguageSelection.vue'
import { GET_UILANGUAGE_ENUM, GET_DOCLANGUAGE_ENUM, GET_CURRENTCY_ENUM } from '@/resources/graphql/query/Enum'
import { GET_SETTING_LANGUAGE } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Language',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_LANGUAGE
    }),
    ui: () => ({
      query: GET_UILANGUAGE_ENUM,
      result ({ data: { __type } }) {
        this.ui = __type.enumValues
      },
      update: (data) => data.__type
    }),
    doc: () => ({
      query: GET_DOCLANGUAGE_ENUM,
      result ({ data: { __type } }) {
        this.doc = __type.enumValues
      },
      update: (data) => data.__type
    }),
    currency: () => ({
      query: GET_CURRENTCY_ENUM,
      result ({ data: { __type } }) {
        this.currency = __type.enumValues
      },
      update: (data) => data.__type
    })
  },
  components: {
    LanguageSelection
  },
  data () {
    return {
      selectedBank: '',
      setting: {},
      ui: [],
      doc: [],
      currency: [],
      state: {
        uiLanguage: 'ready',
        docLanguage: 'ready',
        currency: 'ready'
      }
    }
  },
  methods: {
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>
