var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "pl-12", attrs: { cols: "6" } }, [
            _c(
              "div",
              { staticClass: "d-flex justify-space-between" },
              [
                _vm.text
                  ? _c("text-field-label", { attrs: { label: _vm.text } })
                  : _vm._e(),
                _c("v-spacer"),
                _c(
                  "div",
                  { staticClass: "mr-2 mt-3" },
                  [
                    _vm.loading
                      ? _c("v-progress-circular", {
                          staticClass: "mr-2",
                          attrs: { size: "16", width: "2", indeterminate: "" }
                        })
                      : _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [
                            _c("v-img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.icon && !_vm.isTimeout,
                                  expression: "icon && !isTimeout"
                                }
                              ],
                              attrs: {
                                src: _vm.icon,
                                height: "16",
                                eager: "",
                                contain: ""
                              }
                            })
                          ],
                          1
                        )
                  ],
                  1
                ),
                _c("v-select", {
                  staticClass: "ml-2",
                  staticStyle: { "max-width": "200px" },
                  attrs: {
                    value: _vm.value,
                    items: _vm.items,
                    "item-text": "description",
                    "item-value": "name",
                    dense: "",
                    outlined: "",
                    "hide-details": ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateLanguageSelection($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }