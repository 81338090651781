<template>
  <div>
    <v-row
      class="mt-4"
      no-gutters>
      <v-col
        cols="6"
        class="pl-12">
        <div class="d-flex justify-space-between">
          <text-field-label
            v-if="text"
            :label="text">
          </text-field-label>
          <v-spacer />
          <div class="mr-2 mt-3">
            <v-progress-circular
              v-if="loading"
              class="mr-2"
              size="16"
              width="2"
              indeterminate />
            <transition
              v-else
              name="fade">
              <v-img
                v-show="icon && !isTimeout"
                :src="icon"
                height="16"
                eager
                contain />
            </transition>
          </div>
          <v-select
            :value="value"
            :items="items"
            item-text="description"
            item-value="name"
            style="max-width: 200px;"
            class="ml-2"
            dense
            outlined
            hide-details
            @input="updateLanguageSelection($event)">
          </v-select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelection',
  props: {
    text: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    state: {
      type: String,
      validator: (val) => ['ready', 'loading', 'success', 'error'].includes(val),
      default: () => 'ready'
    }
  },
  data () {
    return {
      timeOut: null,
      isTimeout: false
    }
  },
  computed: {
    loading () {
      return this.state === 'loading'
    },
    icon () {
      switch (this.state) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    },
    isError () {
      return this.state === 'error'
    },
    color () {
      switch (this.state) {
        case 'ready':
        case 'loading':
          return 'primary'
        case 'success':
          return 'success'
        case 'error':
          return 'error'
        default: return 'primary'
      }
    }
  },
  watch: {
    state (value) {
      if (value === 'success') {
        clearTimeout(this.timeOut)
        this.timeOut = setTimeout(() => {
          this.isTimeout = true
        }, 3000)
      } else {
        this.isTimeout = false
      }
    }
  },
  methods: {
    updateLanguageSelection (val) {
      this.$emit('input', val)
    }
  }
}
</script>
