var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("language.header"),
              "prepend-icon": "$languageHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("language.language.title"),
                  "prepend-icon": "$languageTitle"
                }
              }),
              _c("language-selection", {
                attrs: {
                  text: _vm.$t("language.language.uiLanguage"),
                  items: _vm.ui,
                  state: _vm.state.uiLanguage
                },
                on: {
                  input: function($event) {
                    return _vm.submit("uiLanguage")
                  }
                },
                model: {
                  value: _vm.setting.uiLanguage,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "uiLanguage", $$v)
                  },
                  expression: "setting.uiLanguage"
                }
              }),
              _c("language-selection", {
                attrs: {
                  text: _vm.$t("language.language.docLanguage"),
                  items: _vm.doc,
                  state: _vm.state.docLanguage
                },
                on: {
                  input: function($event) {
                    return _vm.submit("docLanguage")
                  }
                },
                model: {
                  value: _vm.setting.docLanguage,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "docLanguage", $$v)
                  },
                  expression: "setting.docLanguage"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("title-label", {
                attrs: {
                  label: _vm.$t("language.currency.title"),
                  "prepend-icon": "$coinTitle"
                }
              }),
              _c("language-selection", {
                attrs: {
                  text: _vm.$t("language.currency.title"),
                  items: _vm.currency,
                  state: _vm.state.currency
                },
                on: {
                  input: function($event) {
                    return _vm.submit("currency")
                  }
                },
                model: {
                  value: _vm.setting.currency,
                  callback: function($$v) {
                    _vm.$set(_vm.setting, "currency", $$v)
                  },
                  expression: "setting.currency"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }